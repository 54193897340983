.projects-section {
    background-color: #f9f9f9;
    padding: 8rem 0;
    overflow: hidden;
  }
  
  .projects-container {
    position: relative; 
    margin: 0 auto;
    height: fit-content;
    text-align: center;
  }
  
  .section-title {
    position: relative;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2rem;
  }
  
  .carousel {
    padding-top: 8rem;
    display: flex;
    justify-content: center;
    overflow-x: auto;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
  }
  
  .carousel-item {
    flex: 0 0 220px;
    margin-right: 1rem;
    scroll-snap-align: end;
  }
  
  
