.experience-card {
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  width: 220px;
  height: auto;
  transition: all 0.3s ease;
  cursor: pointer;
}

.experience-card:hover {
  transform: translateY(-5px);
}

.experience-card-top {
  display: inline;
  text-align: center;
}

.experience-card-bottom {
  margin: 3%;
  text-align: center;
}