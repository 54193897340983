.project-card {
    background-color: #ffffff;
    padding: 1.5rem;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;    
    width: 220px;
    height: 420px;
    transition: all 0.3s ease;
    cursor: pointer;

}

.project-card:hover {
    transform: translateY(-5px);
}

.project-card-top {
    height: fit-content;
    align-items: center;
    justify-content: center;
    
}

.project-card-top img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.project-card-bottom h3 {
    font-size: 1.2rem;
}

.project-card-bottom p {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    white-space: wrap;
    text-overflow:ellipsis;
    color: #676;
}

.project-card:hover .project-card-bottom p {
    display: block;
}
  
