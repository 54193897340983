.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: #ffffff;
  font-size: 16px;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  z-index: 2; /* Add a higher z-index value */
}

.carousel-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.carousel-button:active {
  background-color: rgba(0, 0, 0, 0.9);
}

.carousel-button.next {
  right: 10px;
}

.carousel-button.previous {
  left: 10px;
}

.carousel-container {
  display: flex;
  align-items: center;
  position: relative; /* Add position relative */
}

.arrow-icon.previous {
  transform: scaleX(-1);
}

.carousel-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  position: relative; /* Add position relative */
}
