.header {
    background-color: #222;
    color: #fff;
}

.nav-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo{
        display: block;
        width: 60px;
        height: 60px;
        img{
            display: block;
            margin: 0 auto;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.nav-links {
    justify-content: right;
    list-style: none;
    display: flex;
}

.nav-item {
    margin-right: 1.5rem;
}

.nav-item a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
}

.nav-item a:hover {
    color: #ccc;
}


