.footer {
    padding-top: 30px;
    height: auto;
    text-align: center;
    background-color: #222;
}

.social-media {
    margin-top: 10px;
}

.social-icons {
    color: white;
    font-size: 24px;
    margin-right: 10px;
}

.footer-text {
    color: white;
    margin: 10px 0;
}