.contact-section {
  background-color: #f9f9f9;
  padding: 8rem 0;
  text-align: center;
}
.contact-form {
    margin: 20px auto;
    max-width: 400px;
    padding: 20px;
    background-color: #f2f2f2;
    border-radius: 4px;
    text-align: left;
  }
  
  .contact-form h2 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .contact-form label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    color: #555;
  }
  
  .contact-form input[type="text"],[type="email"],
  .contact-form textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .contact-form textarea {
    height: 120px;
  }
  
  .contact-form input[type="submit"] {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .contact-form input[type="submit"]:hover {
    background-color: #0056b3;
  }
  .success-message{
    text-align: center;
    color: green;
  }

  .contact-section{
    background-color:#f2f2f2
  }
  