.about-section {
    background-color: #f2f2f2;
    padding: 4rem 0;
}

.about-container {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
}

.section-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.section-description {
    font-size: 1.2rem;
    color: #676;
}


