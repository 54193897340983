.education-section{
  background-color: #f9f9f9;
  padding: 4rem 0;
}

.section-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
}
.education {
  text-align: center;
  margin-bottom: 40px;
}

.education h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.education-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}

.institution-link {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #333;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.institution-logo {
  width: 200px;
  padding: 5px;
  height: auto;
  background-color: gray;
}

.education-details {
  font-size: 16px;
  color: #555;
}
