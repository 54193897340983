.experiences-section {
  background-color: #f2f2f2;
  padding: 8rem 0;
}

.experiences-container {
  text-align: center;
  
}
.section-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.experiences-row {
  display: flex;
  justify-content: center;
  gap: 40px;
}
